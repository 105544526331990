import moment from "moment"

export const parseDateTime = (dateTime) => {
  const split = dateTime.split(/\D+/)
  const newDateTime = new Date(
    split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
  )

  return moment(newDateTime).format('DD MMM YYYY hh:mm:ss')
}

export const parseDate = (date) => {
  const split = date.split(/\D+/)
  const newDate = new Date(
    split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
  )

  return moment(newDate).format('YYYY-MM-DD')
}